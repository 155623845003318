import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faPlusCircle,
    faPlusSquare,
    faPrint,
    faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
// import libarty from "../../img/libarty.png";

import {
    Row,
    Col,
    Card,
    Button as Btn1,
    Modal,
    Badge,
    Table as Tbl,
} from "react-bootstrap";
import { faPenAlt, faBook, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";

import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import "./style.css";

import { API_URL } from "./../../global";

const axios = require("axios");

const filter = createFilterOptions();

export default class BillManager extends Component {
    constructor(props) {
        super();

        this.state = {
            newId: 0,
            itemName: null,
            itemsList: [],

            comapanies: [
                {
                    name: "HGS 1",
                    code: "HGS1",
                },
                {
                    name: "HGS 2",
                    code: "HGS2",
                },
            ],
        };
    }

    addRow = () => {
        let itemsList = this.state.itemsList;
        itemsList.push({
            itemName: this.state.itemName,
            itemNo: this.state.itemNo,
            material: this.state.material,
            a: Number(this.state.a),
            b: Number(this.state.b),
            c: Number(this.state.a) + Number(this.state.b),
            d: Number(this.state.d),
            e: Number(this.state.e),
            f: Number(this.state.a) * Number(this.state.e),
            g:
                Number(this.state.a) * Number(this.state.b) +
                Number(this.state.d) +
                Number(this.state.a) * Number(this.state.e),
            ptCost: this.state.ptCost,
            l: Number(this.state.l),
            m: Number(this.state.m),
            n: Number(this.state.n),
            o: Number(this.state.o),
            p: Number(this.state.p),
            t:
                Number(this.state.l) +
                Number(this.state.m) +
                Number(this.state.n) +
                Number(this.state.o) +
                Number(this.state.p),
        });

        this.setState({ itemsList: itemsList });
    };

    deleteItem = (index) => {
        let itemsList = this.state.itemsList;
        let updatedList = itemsList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ itemsList: updatedList });
    };

    handleClear = () => {
        window.location.reload();
        return null;
    };

    fetchNewId() {
        let url = API_URL;
        const query = `select max(id)+1 as newId from billList;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                this.setState({
                    newId: res.data[0].newId,
                });
                console.log(res.data[0].newId);
            })
            .catch((err) => {
                console.log("New Id fetch error: ", err);
            });
    }

    refreshParties() {
        window.location.reload(false);
    }

    componentDidMount() {
        this.fetchNewId();
    }

    render() {
        return (
            <form
                className="mb-5"
                style={{ margin: "10px" }}
                onSubmit={(e) => e.preventDefault()}
            >
                <div
                    className="mt-1 p-2 measure"
                    ref={(el) => (this.printComponentRef = el)}
                >
                    <Row>
                        <Col md={12} className="mx-auto">
                            <Card className="mt-2 p-0">
                                <Card.Header>
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p>
                                            <img
                                                src="Assets/logo.png"
                                                alt="logo"
                                            />
                                        </p>

                                        <div>
                                            <p>
                                                Email :
                                                brothersindustries07@gmail.com
                                            </p>
                                            <p>Mobile No. : +91 7588777800</p>
                                        </div>
                                    </span>

                                    <hr />

                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <p>
                                            Invoice No. :{" "}
                                            <b>{this.state.newId}</b>
                                        </p>
                                        <p>
                                            <b>QUOTATION</b>
                                        </p>
                                        <p>
                                            Date{" "}
                                            <b>
                                                {moment(new Date()).format(
                                                    "D / M / YYYY"
                                                )}
                                            </b>
                                        </p>
                                    </span>
                                </Card.Header>
                                <Card.Body className="pb-3 mb-0">
                                    <table className="w-100 mb-3" align="left">
                                        <tr>
                                            <td>
                                                <h6>To : </h6>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                            <td>
                                                <h6>Company Name : </h6>{" "}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                            <td>
                                                <h6>Project Name : </h6>{" "}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>Mobile No. : </h6>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                            <td>
                                                <h6>Quotation Date : </h6>{" "}
                                            </td>
                                            <td>
                                                <input
                                                    type="date"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                            <td>
                                                <h6>HGS Enquiry Date : </h6>{" "}
                                            </td>
                                            <td>
                                                <input
                                                    type="date"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>Quot Revision : </h6>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                            <td>
                                                <h6>Vendor Quot. No. : </h6>{" "}
                                            </td>
                                            <td>
                                                <input
                                                    type="date"
                                                    class="form-control-sm"
                                                />
                                            </td>
                                        </tr>
                                    </table>

                                    <Tbl
                                        id="rows"
                                        striped
                                        bordered
                                        hover
                                        size="sm"
                                    >
                                        <thead>
                                            <tr>
                                                <th rowSpan={3}>Sr.No.</th>
                                                <th rowSpan={3}>Item Name</th>
                                                <th rowSpan={3}>
                                                    Item No./
                                                    <br />
                                                    Drawing No.
                                                </th>
                                                <th rowSpan={3}>Material</th>
                                                <th rowSpan={2}>
                                                    Casting Weight <br />
                                                    Considered (Kg.)
                                                </th>
                                                <th colSpan={7}>
                                                    Costing USD ($) _ Sea CIF
                                                </th>
                                                <th colSpan={6}>
                                                    LEAD TIME In Weeks
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Raw Casting Rate / Kg.</th>
                                                <th>Raw Casting Cost</th>
                                                <th>
                                                    Machi-ning + Hydro Testing
                                                    Cost
                                                </th>
                                                <th>
                                                    Packing Forwar-ding + CIF
                                                    Per/Kg.
                                                </th>
                                                <th>
                                                    Packing Forwar-ding + CIF
                                                    Cost
                                                </th>
                                                <th>Total CIF</th>
                                                <th>
                                                    Pattern and Tooling Cost
                                                </th>
                                                <th>
                                                    2D & 3D mismatch Check ( 0.5
                                                    Wk Vendor 0.5 Wk HGS )
                                                </th>
                                                <th>
                                                    Weight Checking ( 0.5 Wk
                                                    Vendor 0.5 Wk HGS )
                                                </th>
                                                <th>Pattern</th>
                                                <th>
                                                    Exwork (Casting+ machining+
                                                    Inspection+ Packing )
                                                </th>
                                                <th>Shipping Sea</th>
                                                <th>TOTAL</th>
                                            </tr>
                                            <tr>
                                                <th>A</th>
                                                <th>B</th>
                                                <th>C=(AxB)</th>
                                                <th>D</th>
                                                <th>E</th>
                                                <th>F=(AxE)</th>
                                                <th>G=C+D+F</th>
                                                <th></th>
                                                <th>L</th>
                                                <th>M</th>
                                                <th>N</th>
                                                <th>O</th>
                                                <th>P</th>
                                                <th>T=L+M+N+O+P</th>
                                            </tr>
                                            <tr className="d-print-none">
                                                <td></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="7"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                itemName:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="7"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                itemNo: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="7"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                material:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                a: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                b: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                d: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                e: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                PtCost: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                l: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                m: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                n: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                o: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        size="1"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                p: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td></td>
                                                <td>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.addRow}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlusSquare}
                                                        />
                                                    </Button>
                                                </td>
                                            </tr>
                                        </thead>
                                        {this.state.itemsList.length > 0 ? (
                                            <tbody>
                                                {this.state.itemsList.map(
                                                    (item, index) => {
                                                        return (
                                                            // <tr key={"" + item.particularValue.title}>
                                                            //   <td>{item.particularValue.title} </td>
                                                            <tr key={index}>
                                                                <td>
                                                                    {index + 1}{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.itemName
                                                                    }{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.itemNo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.material
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.a}
                                                                </td>
                                                                <td>
                                                                    {item.b}
                                                                </td>
                                                                <td>
                                                                    {item.c}
                                                                </td>
                                                                <td>
                                                                    {item.d}
                                                                </td>
                                                                <td>
                                                                    {item.e}
                                                                </td>

                                                                <td>
                                                                    {item.f}
                                                                </td>
                                                                <td>
                                                                    {item.g}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.ptCost
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.l}
                                                                </td>
                                                                <td>
                                                                    {item.m}
                                                                </td>

                                                                <td>
                                                                    {item.n}
                                                                </td>
                                                                <td>
                                                                    {item.o}
                                                                </td>
                                                                <td>
                                                                    {item.p}
                                                                </td>
                                                                <td>
                                                                    {item.t}
                                                                </td>
                                                                <td
                                                                    className="d-print-none"
                                                                    align="center"
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() =>
                                                                            this.deleteItem(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="16">
                                                        No items added
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Tbl>
                                    <hr />
                                    <div className="mt-3">
                                        <h6>Note: </h6>
                                        <ol>
                                            <li>
                                                If 2D/3D mismatch or weight
                                                issues delayed than 2 week, then
                                                lead time will start From
                                                Pattern
                                            </li>
                                            <li>
                                                USD to INR conversion rate
                                                considered as 70
                                            </li>
                                            <li>
                                                Quality Requirements are
                                                considered as per HGS Enquiry
                                            </li>
                                            <li>
                                                Casting weight will be handled
                                                as per HGS Guideline
                                                "HGS-LCC-P-003"
                                            </li>
                                            <li>
                                                Delivery – CIF, Delivery
                                                Schedule will be submitted later
                                                as per volume and HGS Requirmnt.
                                            </li>
                                        </ol>
                                    </div>
                                    <hr />
                                    <div>
                                        <div class="row">
                                            <div className="col col-12 col-md-12">
                                                <p class="text-right">
                                                    Thanks and Regards, <br />
                                                    Nilesh Suryawanshi,
                                                    <br /> CEO, Brothers
                                                    Industries
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="col-12">
                    <ReactToPrint content={() => this.printComponentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button
                                    onClick={handlePrint}
                                    className="mt-2 mr-1"
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right" }}
                                >
                                    <FontAwesomeIcon icon={faPrint} />
                                    &nbsp;&nbsp;Print
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>

                    <Button
                        className="mt-2 mr-1"
                        color="primary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp;&nbsp; clear
                    </Button>
                </div>
            </form>
        );
    }
}
